"use client";
import Image from "next/image";
import LoginButton from "./login-button";
import { Suspense, useState } from "react";
import { signIn } from "next-auth/react";
import { useRouter } from "next/navigation";
import { Form, Spin } from "antd";
import FormInput from "@components/form-items/FormInput";
import { useTranslation } from "@/app/i18n/client";
const rules = {
  email: [{ required: true }],
  password: [{ required: true }],
};
export default function LoginPage() {
  const router = useRouter();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("common");
  let [error, setError] = useState<String | undefined>(undefined);

  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async () => {
        const values = form.getFieldsValue() || {};
        setIsLoading(true);
        const data = await signIn("credentials", {
          redirect: false,
          email: values.email,
          password: values.password,
        });

        if (data?.error) {
          setError(data.error);
          return;
        }
        if (data?.ok && data.status === 200) {
          window.location.href = "/";
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Spin spinning={isLoading} tip="Loading...">
      <div className="mx-auto border border-stone-200 py-10 sm:w-full sm:max-w-md sm:rounded-lg sm:shadow-md dark:border-stone-700">
        <Form
          form={form}
          onFinish={handleSubmit}
          autoComplete="off"
          layout="vertical"
        >
          <div className="flex w-full items-center justify-center">
            <Image
              alt="Platforms Starter Kit"
              width={100}
              height={100}
              className="relative mx-auto h-12 w-auto dark:scale-110 dark:rounded-full dark:border dark:border-stone-400"
              src="/logo.png"
            />
          </div>
          <h1 className="font-cal mt-6 text-center text-3xl dark:text-white">
            Sign in to your account
          </h1>

          <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
            <div className="space-y-4 md:space-y-6">
              <div>
                {/* <label
                htmlFor="email"
                className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Your email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                placeholder="name@company.com"
              /> */}
                <FormInput
                  name="email"
                  placeholder="name@company.com"
                  className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 sm:text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  label={t("login.email")}
                  rule={rules.email}
                ></FormInput>
              </div>
              <div>
                {/* <label
                htmlFor="password"
                className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
              /> */}
                <FormInput
                  name="password"
                  type="password"
                  placeholder="••••••••"
                  className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 sm:text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  label={t("login.password")}
                  rule={rules.password}
                ></FormInput>
              </div>
              {error && error.length > 0 && (
                <div className="text-red-700">{error}</div>
              )}
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 h-4 w-4 rounded border border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="remember"
                      className="text-gray-500 dark:text-gray-300"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <a
                  href="#"
                  className="text-primary-600 dark:text-primary-500 text-sm font-medium hover:underline"
                >
                  Forgot password?
                </a>
              </div>

              <Suspense
                fallback={
                  <div className="my-2 h-10 w-full rounded-md border border-stone-200 bg-stone-100 dark:border-stone-700 dark:bg-stone-800" />
                }
              >
                <button
                  type="submit"
                  className="group my-2 flex h-10 w-full items-center justify-center space-x-2 rounded-md border border-stone-200 transition-colors duration-75 focus:outline-none dark:border-stone-700"
                  onClick={handleSubmit}
                >
                  Sign in
                </button>
                <LoginButton />
              </Suspense>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Don’t have an account yet?{" "}
                <a
                  href="#"
                  className="text-primary-600 dark:text-primary-500 font-medium hover:underline"
                >
                  Sign up
                </a>
              </p>
            </div>
          </div>
        </Form>
      </div>
    </Spin>
  );
}
